import { createStoreAction } from '@cobuildlab/react-simple-state';
import { changePlanStore } from './payment-events';
import { Plan } from '../../shared/types/generated';

export const changePlanStoreAction = createStoreAction(
  changePlanStore,
  (prev, plan: Plan | undefined) => ({
    ...prev,
    plan,
  }),
);
