import { UserRoles } from '../../shared/types/generated';
import { RBACPermissions } from './rbac-types';
import { GUEST_ROLE } from '../../shared/constans';

export const RBACSchema = [
  {
    Role: UserRoles.User,
    Permissions: [RBACPermissions.CAN_SUBSCRIPTION],
  },
  {
    Role: GUEST_ROLE,
    Permissions: [RBACPermissions.CAN_SUBSCRIPTION],
  },
];
