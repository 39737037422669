import React from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { IdObj, TableActionsMenuProps, TableDefaultProps } from './table-types';
import { MainLoader as Loading } from '../MainLoader';
import { MoreVert } from '@mui/icons-material';

export const TableActionsMenu = <T extends IdObj>(
  props: React.PropsWithChildren<TableActionsMenuProps<T>>,
): JSX.Element => {
  const { actions, item } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openClientMenu = Boolean(anchorEl);

  const handleOpenClientMenu = (event: React.MouseEvent<HTMLElement>): void =>
    setAnchorEl(event.currentTarget);

  const handleCloseClientMenu = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleOpenClientMenu} size="small">
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={openClientMenu}
        onClose={handleCloseClientMenu}
      >
        {actions.map((action) => {
          const disabled = action.isDisabled ? action.isDisabled(item) : false;
          return (
            <MenuItem
              onClick={() => {
                action.onClick(item);
                handleCloseClientMenu();
              }}
              disabled={disabled}
            >
              {typeof action.actionName === 'function'
                ? action.actionName(item)
                : action.actionName}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export const TableDefault = <T extends IdObj>(
  props: TableDefaultProps<T>,
): JSX.Element => {
  const {
    columns,
    items,
    loading,
    messageLoading,
    actions,
    showPagination,
    page,
    pageSize,
    count = 0,
    handleChangePage,
  } = props;

  let totalColumns = columns.length;

  const tableHead = columns.map((column) => (
    <TableCell align="center">{column.columnName}</TableCell>
  ));

  if (actions && actions.length > 0) {
    totalColumns += 1;
    tableHead.push(<TableCell align="center">ACCIONES</TableCell>);
  }

  const tableBody = loading ? (
    <TableRow>
      <TableCell colSpan={totalColumns}>
        <Loading height="350px">{messageLoading}</Loading>
      </TableCell>
    </TableRow>
  ) : (
    <>
      {items.map((item, i) => {
        const index = i + 1;

        const columnsData = columns.map((column) => {
          if (column.columnValue !== null) {
            return (
              <TableCell
                align="center"
                key={`table-default-pro__cell--${Math.random() + index}`}
              >
                {column.columnValue(item, index)}
              </TableCell>
            );
          }
          if (column.columnComponent)
            return (
              <TableCell
                align="center"
                key={`table-default-pro__cell--${Math.random() + index}`}
              >
                {column.columnComponent(item, index)}
              </TableCell>
            );
          return null;
        });

        if (actions && actions.length > 0) {
          columnsData.push(
            <TableCell align="center">
              <TableActionsMenu item={item} actions={actions} />
            </TableCell>,
          );
        }

        return <TableRow>{columnsData}</TableRow>;
      })}
    </>
  );

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>{tableHead}</TableHead>
          <TableBody>{tableBody}</TableBody>
        </Table>
      </TableContainer>
      {showPagination && (
        <TablePagination
          count={count}
          component="div"
          page={page - 1}
          rowsPerPage={pageSize}
          onPageChange={(event: unknown, newPage: number) => {
            if (handleChangePage) handleChangePage(newPage);
          }}
          rowsPerPageOptions={[]}
        />
      )}
    </>
  );
};
