import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { createAnalyticEvent } from './analytic-event-actions';
import { AnalyticsEvents } from './analytics-event-types';

export const useAnalyticEventHook = (
  name: AnalyticsEvents,
  userId = 0,
): void => {
  const [cookies] = useCookies(['analytics_uuid']);
  const [callAnalyticEvent] = useCallAction(createAnalyticEvent);

  useEffect(() => {
    if (cookies.analytics_uuid) {
      callAnalyticEvent({
        name: name,
        user_id: userId,
        uuid: cookies.analytics_uuid,
      });
    }
  }, [callAnalyticEvent, cookies.analytics_uuid, name, userId]);
};

export const useAnalyticEventCallback = (): {
  analyticsCallback: (name: AnalyticsEvents, userId?: number) => void;
} => {
  const [cookies] = useCookies(['analytics_uuid']);

  const [callAnalyticEvent] = useCallAction(createAnalyticEvent);
  const analyticsCallback = (name: AnalyticsEvents, userId = 0): void => {
    callAnalyticEvent({
      name: name,
      user_id: userId,
      uuid: cookies.analytics_uuid,
    });
  };

  return {
    analyticsCallback,
  };
};
