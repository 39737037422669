import { SubscriptionStatuses } from '../../shared/types/generated';

export const getStatusTextColor = (
  status?: SubscriptionStatuses | null,
): string => {
  let text = '';
  switch (status) {
    case SubscriptionStatuses.Active:
      text = 'green';
      break;
    case SubscriptionStatuses.Inactive:
      text = '#e9bd15';
      break;
    case SubscriptionStatuses.Cancelled:
      text = 'red';
      break;
  }
  return text;
};

export const getSubscriptionStatusText = (
  status?: SubscriptionStatuses | null,
): string => {
  let text = '';
  switch (status) {
    case SubscriptionStatuses.Active:
      text = 'Activo';
      break;
    case SubscriptionStatuses.Inactive:
      text = 'Vencido';
      break;
    case SubscriptionStatuses.Cancelled:
      text = 'Cancelado';
      break;
  }
  return text;
};
