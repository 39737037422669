import { createEvent } from '@cobuildlab/react-simple-state';
import {
  AnalyticsAuthentication,
  AnalyticsEvenType,
} from './analytics-event-types';

export const createAnalyticsEvent = createEvent<{
  analyticEvent: AnalyticsEvenType | undefined;
}>({
  initialValue: {
    analyticEvent: undefined,
  },
});
export const createAnalyticsErrorEvent = createEvent();

export const createAnalyticsAuthenticationEvent =
  createEvent<AnalyticsAuthentication>({
    initialValue: {
      uuid: '',
      message: '',
    },
  });
export const createAnalyticsAuthenticationErrorEvent = createEvent();
