import React from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { IdObj, MenuActionProps } from './menu-types';

export const MenuActions = <T extends IdObj>(
  props: React.PropsWithChildren<MenuActionProps<T>>,
): JSX.Element => {
  const { actions, item } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openClientMenu = Boolean(anchorEl);

  const handleOpenClientMenu = (event: React.MouseEvent<HTMLElement>): void =>
    setAnchorEl(event.currentTarget);

  const handleCloseClientMenu = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleOpenClientMenu} size="small">
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={openClientMenu}
        onClose={handleCloseClientMenu}
      >
        {actions.map((action) => {
          const disabled = action.isDisabled ? action.isDisabled(item) : false;
          return (
            <MenuItem
              onClick={() => {
                action.onClick(item);
                handleCloseClientMenu();
              }}
              disabled={disabled}
            >
              {typeof action.actionName === 'function'
                ? action.actionName(item)
                : action.actionName}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
