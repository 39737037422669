import React, { useEffect, useState } from 'react';
import { Provider } from './AuthContext';
import { useCookies } from 'react-cookie';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import {
  ANALYTICS_COOKIES_OPTIONS,
  COOKIES_OPTIONS,
  USER_COOKIE,
} from '../../shared/constans';
import { createAnalyticAuthenticationAction } from '../analytic-event/analytic-event-actions';
import { User, useCurrentUserLazyQuery } from '../../shared/types/generated';
import { universalCookies } from '../../shared/cookies';
import { OnTokenEvent } from './auth-events';
import { useNavigate } from 'react-router-dom';

type AuthProviderProps = {
  children?: React.ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [cookies, setCookie] = useCookies([USER_COOKIE, 'analytics_uuid']);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticate] = useState<boolean>(
    !!universalCookies.get(USER_COOKIE),
  );
  const [user, setUser] = useState<User | undefined | null>(undefined);
  const navigate = useNavigate();
  const { token } = useEvent(OnTokenEvent);

  const [createAnalyticsAuthenticationCallback] = useCallAction(
    createAnalyticAuthenticationAction,
    {
      onCompleted: (data) => {
        setCookie('analytics_uuid', data.uuid, ANALYTICS_COOKIES_OPTIONS);
      },
    },
  );

  useEffect(() => {
    if (!cookies.analytics_uuid) {
      createAnalyticsAuthenticationCallback();
    }
  }, [
    cookies.analytics_uuid,
    setCookie,
    createAnalyticsAuthenticationCallback,
  ]);

  const [currentUser] = useCurrentUserLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setLoading(false);
      setIsAuthenticate(true);
      setUser(data?.currentUser);
    },
    onError: () => {
      universalCookies.remove(USER_COOKIE, COOKIES_OPTIONS);
      OnTokenEvent.dispatch({ token: null });
      navigate('/autenticacion');
    },
  });

  useEffect(() => {
    if (token) {
      currentUser();
    } else {
      setLoading(false);
    }
  }, [currentUser, token]);

  const refetch = (): void => {
    currentUser();
  };

  const values = {
    isAuthenticated,
    isLoading,
    user,
    refetch,
    provider: universalCookies.get('_provider'),
  };

  return <Provider value={values}>{children}</Provider>;
};
