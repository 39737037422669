import { InvoiceStatuses } from '../../shared/types/generated';

export const getStatusText = (status?: InvoiceStatuses | null): string => {
  let text = '';
  switch (status) {
    case InvoiceStatuses.Paid:
      text = 'Pagado';
      break;
    case InvoiceStatuses.PaymentPending:
      text = 'En proceso';
      break;
    case InvoiceStatuses.Cancelled:
      text = 'Fallido';
      break;
  }

  return text;
};
