import React, { ReactElement, useEffect, useState } from 'react';

import { useAuth } from '../auth/auth-hook';
import { checkUserRole } from './rbac-utils';
import { RBACPermissions } from './rbac-types';
import { ErrorView } from './ErrorView';

type RoleAuthorizationProps = {
  render: () => ReactElement<string | React.JSXElementConstructor<unknown>>; // eslint-disable-line @typescript-eslint/no-explicit-any
  error?: () => ReactElement<
    string | React.JSXElementConstructor<unknown>
  > | null; // eslint-disable-line @typescript-eslint/no-explicit-any
  permission: RBACPermissions;
};

export const RoleAuthorization: React.FC<RoleAuthorizationProps> = ({
  render,
  error = () => <ErrorView />,
  permission,
}) => {
  const { user } = useAuth();
  const [checkAuth, setCheckAuth] = useState<boolean>(
    checkUserRole(user, permission),
  );
  useEffect(() => {
    setCheckAuth(checkUserRole(user, permission));
  }, [permission, user]);

  if (checkAuth) return render();
  return error();
};
