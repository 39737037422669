import { CreditCard } from './open-pay-types';
import cardValidator from 'card-validator';

export const checkCreditCard = (creditCard: CreditCard): string | null => {
  const checkCreditCardField = Object.keys(creditCard).find(
    (_creditCard) => creditCard[_creditCard as keyof CreditCard] === '',
  );

  if (checkCreditCardField) {
    return 'Todos los campos de la tarjeta son requeridos';
  }

  if (!cardValidator.number(creditCard.creditCardNumber).isValid) {
    return 'El numero de tarjeta es incorrecto';
  }

  if (!cardValidator.cvv(creditCard.cvc).isValid) {
    return 'El CVV es incorrecto';
  }

  if (!cardValidator.expirationDate(creditCard.expiredDate).isValid) {
    return 'La fecha de expiración es incorrecta';
  }

  return null;
};
